"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
console.debug('INFO Contactformdropdownprefill.ts loaded');
var Contactformdropdownprefill = /** @class */ (function () {
    function Contactformdropdownprefill(component) {
        this.component = component;
        var urlParams = new URLSearchParams(window.location.search);
        var disableDropdownForPrefill = component.getAttribute('data-contactform-dropdown-prefill-disabled-dropdown-for-prefill');
        var prefillParameterName = component.getAttribute('data-contactform-dropdown-prefill-parameter-name');
        //get parameter from url and check if one option has this value
        var prefillParameterValueFromUrl = urlParams.get(prefillParameterName);
        this.preselectDropdown(prefillParameterValueFromUrl, disableDropdownForPrefill);
    }
    Contactformdropdownprefill.prototype.preselectDropdown = function (prefillParameterValueFromUrl, disableDropdownForPrefill) {
        if (prefillParameterValueFromUrl) {
            var matchingOption = this.component.querySelector("[data-contactform-dropdown-prefill-value='".concat(prefillParameterValueFromUrl, "']"));
            //select the option matching to url parameter
            if (matchingOption) {
                var select = this.component.querySelector('.js-contact-form-dropdown');
                matchingOption.selected = true;
                //set read only if the field should not be changed after prefill
                if (disableDropdownForPrefill === 'true') {
                    select.setAttribute('readonly', 'true');
                }
            }
        }
    };
    return Contactformdropdownprefill;
}());
exports.default = Contactformdropdownprefill;
