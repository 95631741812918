"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileErrorAttributeName = void 0;
var FileErrorAttributeName;
(function (FileErrorAttributeName) {
    FileErrorAttributeName["FILE"] = "data-file-error-message";
    FileErrorAttributeName["FILECOUNT"] = "data-filecount-error-message";
    FileErrorAttributeName["FILESIZE"] = "data-filesize-error-message";
    FileErrorAttributeName["FILEFORMAT"] = "data-fileformat-error-message";
    FileErrorAttributeName["NOFILE"] = "data-nofile-error-message";
})(FileErrorAttributeName = exports.FileErrorAttributeName || (exports.FileErrorAttributeName = {}));
