"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FreeTextFieldPrefill = /** @class */ (function () {
    function FreeTextFieldPrefill(component) {
        this.component = component;
        var urlParams = new URLSearchParams(window.location.search);
        var urlParameterName = component.getAttribute('data-free-text-field-url-parameter-name');
        //get parameter from url and check if one option has this value
        var prefillParameterValueFromUrl = urlParams.get(urlParameterName);
        if (prefillParameterValueFromUrl) {
            this.prefill(prefillParameterValueFromUrl);
        }
    }
    FreeTextFieldPrefill.prototype.prefill = function (prefillParameterValueFromUrl) {
        var input = this.component.querySelector('.js-contact-form-input-field');
        input.value = prefillParameterValueFromUrl;
        if (input.hasAttribute('data-field-readonly')) {
            input.setAttribute('readonly', 'true');
        }
    };
    return FreeTextFieldPrefill;
}());
exports.default = FreeTextFieldPrefill;
