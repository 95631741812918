"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
console.debug('INFO AbstractValidatonPlugin.ts loaded');
var AbstractValidationPlugin = /** @class */ (function () {
    function AbstractValidationPlugin(field) {
        this.field = field;
    }
    AbstractValidationPlugin.prototype.runCustomValidation = function () {
        // do nothing (override in child classes)
    };
    AbstractValidationPlugin.prototype.isValid = function () {
        return true;
    };
    AbstractValidationPlugin.prototype.getMessage = function () {
        if (!this.isValid()) {
            return this._getMessage();
        }
        else {
            return '';
        }
    };
    AbstractValidationPlugin.prototype._getMessage = function () {
        if (this.field.parentElement.hasAttribute(this._getMessageAttribute())) {
            // use custom error message on field
            return this.field.parentElement.getAttribute(this._getMessageAttribute());
        }
        else if (this.field.form.hasAttribute(this._getMessageAttribute())) {
            // use custom error message on form (default for all fields)
            return this.field.form.getAttribute(this._getMessageAttribute());
        }
        else {
            // use default message from plugin
            return this._getDefaultMessage();
        }
    };
    AbstractValidationPlugin.prototype._getMessageAttribute = function () {
        return 'data-custom-error-message';
    };
    AbstractValidationPlugin.prototype._getDefaultMessage = function () {
        return 'Please fill this field correctly.';
    };
    return AbstractValidationPlugin;
}());
exports.default = AbstractValidationPlugin;
